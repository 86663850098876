import { store } from '@/store/store';
import { TodasLasCita } from '@/shared/dtos/TodasLasCita';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'TodasLasCitasModule',
    store,
    dynamic: true
})
class TodasLasCitasModule extends VuexModule {
    public TodasLasCitas: TodasLasCita[] = [];

    @Action({ commit: 'onGetTodasLasCitass' })
    public async getTodasLasCitass() {
        return await ssmHttpService.get(API.DashCitasRealizadas, null, false);
    }

    @Mutation
    public onGetTodasLasCitass(res: TodasLasCita[]) {
        this.TodasLasCitas = res ? res.map((x) => new TodasLasCita(x)) : [];
    }
}
export default getModule(TodasLasCitasModule);