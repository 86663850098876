







































import { Component, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { DashColors } from "@/views/Dashboards/Dashcolors";
import TodasLasCitas from "@/store/modules/Dashboards/TodasLasCitas-module";
@Component({
  components: {
    VueApexCharts
  }
})
export default class EvolucionCitas extends Vue {
  public AnosSeleccionadosItems: number[] = [];
  public ejecutado = false;
  public created() {
    TodasLasCitas.getTodasLasCitass();
  }

  public get DataSerie() {
    let dataSerie: number[] = [];
    //hay que poner un try sino no te lo pinta
    try {
      for (let i = 0; i < TodasLasCitas.TodasLasCitas.length; i++) {
        let ano = Number.parseInt(
          TodasLasCitas.TodasLasCitas[i].fechaFormat.toString().substring(0, 4)
        );
        let encontrado = false;
        for (let j = 0; j < this.AnosSeleccionadosItems.length; j++) {
          if (this.AnosSeleccionadosItems[j] === ano) {
            encontrado = true;
          }
        }
        if (encontrado) {
          dataSerie.push(TodasLasCitas.TodasLasCitas[i].total);
        }
      }
    } catch (error) {}

    return dataSerie;
  }
  public get DataCategories() {
    let dataCategories: Date[] = [];
    //hay que poner un try sino no te lo pinta
    try {
      for (let i = 0; i < TodasLasCitas.TodasLasCitas.length; i++) {
        let ano = Number.parseInt(
          TodasLasCitas.TodasLasCitas[i].fechaFormat.toString().substring(0, 4)
        );
        let encontrado = false;
        for (let j = 0; j < this.AnosSeleccionadosItems.length; j++) {
          if (this.AnosSeleccionadosItems[j] === ano) {
            encontrado = true;
          }
        }
        if (encontrado) {
          dataCategories.push(TodasLasCitas.TodasLasCitas[i].fechaFormat);
        }
      }
    } catch (error) {}
    return dataCategories;
  }

  public get Años() {
    let todosLosaños: any[] = [];

    //hay que poner un try sino no te lo pinta
    try {
      for (let i = 0; i < TodasLasCitas.TodasLasCitas.length; i++) {
        todosLosaños.push(
          Number.parseInt(
            TodasLasCitas.TodasLasCitas[i].fechaFormat
              .toString()
              .substring(0, 4)
          )
        );
      }
    } catch (error) {}
    for (let i = 0; i < todosLosaños.length; i++) {
      let encontrado = false;
      for (let j = 0; j < this.AnosSeleccionadosItems.length; j++) {
        if (this.AnosSeleccionadosItems[j] === todosLosaños[i]) {
          encontrado = true;
        }
      }
      if (!encontrado && !this.ejecutado) {
        this.AnosSeleccionadosItems.push(todosLosaños[i]);
      }
    }
    this.ejecutado = this.AnosSeleccionadosItems.length > 0;
    return todosLosaños;
  }

  public get lineAreaChartSpline() {
    return {
      series: [
        {
          name: "citas",
          data: this.DataSerie
        }
      ],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        colors: DashColors.colors,
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        markers: {
          size: 5,
          hover: {
            size: 9
          }
        },
        xaxis: {
          type: "datetime",
          categories: this.DataCategories
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm"
          }
        }
      }
    };
  }
}
